
import React, { useEffect } from 'react'
import { Checkbox, Button, Popover, PopoverPosition, Intent } from "@blueprintjs/core"
import { DatePicker, TimePrecision } from "@blueprintjs/datetime"
import { TimezonePicker } from "@blueprintjs/timezone"
import moment from 'moment'
import { Table, Column, Cell } from "@blueprintjs/table"
import "@blueprintjs/table/lib/css/table.css"
import ElementGroup from "../components/ElementGroup.js"
import { DropdownMenu, ValueCheckbox } from "../components/Form.js"
import tempoFa from "../components/tempo_fa.js"
import * as api from '../api/influx_api.js'
import { atom, useAtom, useAtomValue, getDefaultStore } from "jotai"

const store = getDefaultStore()

const state = {
	// Received from server
	database_list:       atom([]),
	table_list:          atom([]),
	series_list:         atom([]),
	// User selection
	current_db:          atom(""),
	current_table:       atom(""),
	selected_rows:       atom([]),
	// UI parameters
	ts_first:            atom(345600000), // 05/01/1970, earlier date produce an error
	ts_last:             atom(Date.now()),
	tz:                  atom(moment.tz.guess()),
	use_relative_time:   atom(false),
	// loading states
	loading_databases:   atom(false),
	loading_tables:      atom(false),
	loading_series:      atom(false)
}


const getDatabaseList = () => {
	store.set(state.database_list, [])
	store.set(state.table_list, [])
	store.set(state.series_list, [])
	store.set(state.loading_databases, true)
	store.set(state.current_table, "")
	return api.getDatabases().then((data) => {
		store.set(state.database_list, data)
	}).finally(() => {
		store.set(state.loading_databases, false)
	})
}

const getTableList = () => {
	store.set(state.loading_tables, true)
	return api.getTables(store.get(state.current_db)).then((data) => {
		store.set(state.table_list, data)
	}).finally(() => {
		store.set(state.loading_tables, false)
	})
}


const onRowSelect = (row_id, checked) => {
	const previously_selected_rows = store.get(state.selected_rows)
	if (checked)
		// append row_id to `selected_rows`
		store.set(state.selected_rows, [...previously_selected_rows, row_id])
	else {
		// remove row_id from `selected_rows`
		store.set(state.selected_rows, previously_selected_rows.filter((i) => i !== row_id))
	}
}


const getSeriesList = () => {
	store.set(state.loading_series, true)
	store.set(state.selected_rows, [])

	return api.getSeriesList(
		store.get(state.current_db), 
		store.get(state.current_table), 
		store.get(state.ts_first), 
		store.get(state.ts_last)
	).then((data) => {
		store.set(state.series_list, data)
	}).catch((e) => {
		store.set(state.series_list, [])
	}).finally(() => {
		store.set(state.loading_series, false)
	})
}


const SummaryTable = () => {
	const isLoading         = useAtomValue(state.loading_series)
	const selected_rows     = useAtomValue(state.selected_rows)
	const use_relative_time = useAtomValue(state.use_relative_time)
	const series_list       = useAtomValue(state.series_list)

	const toDtString = (timestamp: number | undefined): string => {
		if (timestamp) {
			var date = new Date(timestamp * 1000);
			if (use_relative_time) {
				return tempoFa(date)
			}
			// <3 stackoverflow
			return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
		}
		return "Nessun dato"
	}

	const nameRenderer    = (row_index) => <Cell loading={isLoading}>{series_list[row_index]?.name}</Cell>
	const countRenderer   = (row_index) => <Cell loading={isLoading}>{series_list[row_index].count?.toString()}</Cell>
	const vminRenderer    = (row_index) => <Cell loading={isLoading}>{series_list[row_index].min?.toFixed(3)}</Cell>
	const vmaxRenderer    = (row_index) => <Cell loading={isLoading}>{series_list[row_index].max?.toFixed(3)}</Cell>
	const avgRenderer     = (row_index) => <Cell loading={isLoading}>{series_list[row_index].avg?.toFixed(3)}</Cell>
	const lastTsRenderer  = (row_index) => <Cell loading={isLoading}>{toDtString(series_list[row_index]?.t_last)}</Cell>
	const firstTsRenderer = (row_index) => <Cell loading={isLoading}>{toDtString(series_list[row_index]?.t_first)}</Cell>

	const render_key = `idbc-${use_relative_time}` // force table to re-render when property `use_relative_time` changes

	const rowSelectRenderer = (row_index) => {
		return (
			<Checkbox
				checked={selected_rows.includes(row_index)}
				onClick={(e)=>{
					onRowSelect(row_index, e.target.checked)
				}}
			/>
		)
	}

	return (
		<Table 
			key={render_key}
			numRows={series_list?.length} 
			columnWidths={[30, 150,100,100,100,100,120,120]}
			id="idbc-table"
		>
			<Column name=" " cellRenderer={rowSelectRenderer} />
			<Column name="Nome" cellRenderer={nameRenderer} />
			<Column name="Quantità" cellRenderer={countRenderer}  />
			<Column name="V. min" cellRenderer={vminRenderer} />
			<Column name="V. max" cellRenderer={vmaxRenderer} />
			<Column name="V. medio" cellRenderer={avgRenderer} />
			<Column name="Prima misura" cellRenderer={firstTsRenderer} />
			<Column name="Ultima misura" cellRenderer={lastTsRenderer} />
		</Table>
	)
}


const DBSelector = ({priority_items = []}) => {
	const items = useAtomValue(state.database_list)
	const value = useAtomValue(state.current_db)
	const sorted_db_list = [
		...priority_items.map((e) => {return ({label: <b>{e}</b>, value: e})}),
		...items.sort().map((e) => {return ({label: e, value: e})})
	]
	const is_loading = useAtomValue(state.loading_databases)

	const onChange = (e) => {
		store.set(state.current_db, e.value)
		store.set(state.current_table, "")
		store.set(state.series_list, [])
	}

	return (
		<DropdownMenu
			vertical
			label="Database"
			defaultValue={{label: "seleziona...", value: null}}
			value={{label: value, value: value}}
			options={sorted_db_list}
			isSearchable={true}
			isLoading={is_loading}
			onChange={onChange}
			onClick={getDatabaseList}
		/>
	)
}


const TableSelector = () => {
	const items          = useAtomValue(state.table_list);
	const loading        = useAtomValue(state.loading_tables);
	const disabled       = useAtomValue(state.current_db) ? false : true;
	const [current_table, setCurrentTable] = useAtom(state.current_table);
	const onChange = (e) => {
		setCurrentTable(e.value)
	}

	return (
		<DropdownMenu
			vertical
			label="Tabella"
			defaultValue={{label: "seleziona...", value: null}}
			value={{label: current_table, value: current_table}}
			options={items.map((e) => {return ({label: e, value: e})})}
			isSearchable={true}
			isLoading={loading}
			isDisabled={disabled}
			onChange={onChange}
			onClick={getTableList}
		/>
	)
}

const DateTimePicker = (props) => {
	return (
		<DatePicker
			timePrecision={TimePrecision.SECOND}
			includeTime={true}
			label="prova123"
			highlightCurrentDay={true}
			shortcuts={true}
			minDate={new Date(0)}
			{...props}
		/>
	)
}

const InfluxDBClient = ({priority_database_list = []}) =>  {
	
	const current_db     = useAtomValue(state.current_db)
	const selected_rows  = useAtomValue(state.selected_rows)
	const current_table  = useAtomValue(state.current_table)
	const series_list    = useAtomValue(state.series_list)

	const [ts_first, setTsFirst]  = useAtom(state.ts_first)
	const [ts_last, setTsLast]    = useAtom(state.ts_last)
	const [tz, setTz]             = useAtom(state.tz)
	const [use_relative_time, setUseRelativeTime] = useAtom(state.use_relative_time)
	const [loading_series, setLoadingSeries] = useAtom(state.loading_series)


	useEffect (() => {
		// componentDidMount
		getDatabaseList()
	}, [])

	useEffect (() => {
		// current database changed
		if (current_db !== "") {
			getTableList()
		}
	}, [current_db])

	useEffect (() => {
		// current table or time range changed
		if (current_table !== "") {
			getSeriesList()
		}
	}, [current_table, ts_first, ts_last, tz])

	useEffect (() => {
		// console.log("Selected rows %o", selected_rows)
	}, [selected_rows])

	const ts_first_label  = use_relative_time ? tempoFa(new Date(ts_first)) : moment(ts_first).tz(tz).format()
	const ts_last_label   = use_relative_time ? tempoFa(new Date(ts_last))  : moment(ts_last).tz(tz).format()

	const selected_series = selected_rows.map((i) => series_list[i].name)

	return (
		<div className={`area-influxdb-client ${loading_series ? "busy_loading" : ""}`}>
			<ElementGroup caption="InfluxDB" className="area-table-selector">
				<DBSelector 
					priority_items={priority_database_list}
				/>
				<TableSelector />
			</ElementGroup>
			<ElementGroup caption="Data" className="area-timerange-selector">
				<p>
				<TimezonePicker 
					value={tz}
					defaultValue
					onChange={(tz) => {
						setTz(tz)
					}}
					valueDisplayFormat="composite"
					popoverProps={{
						position: PopoverPosition.BOTTOM
					}}
				/>
				</p>
				<p>
					<Popover position={PopoverPosition.RIGHT} content={
						<div onClick={(e) => e.stopPropagation()} >
							<DateTimePicker
								value={new Date(ts_first)}
								onChange={(date) => {	
									setTsFirst(date.getTime())
								}}
							/>
						</div>
					}>
						<Button><strong>Inizio </strong>{ts_first_label}</Button>
					</Popover>
				</p>
				<p>
					<Popover position={PopoverPosition.RIGHT} content={
						<div onClick={(e) => e.stopPropagation()} >
							<DateTimePicker
								value={new Date(ts_last)}
								onChange={(date) => {
									setTsLast(date.getTime())
								}}
							/>
						</div>
					}>
						<Button><strong>Fine&nbsp;&nbsp;&nbsp;</strong>{ts_last_label}</Button>
					</Popover>
				</p>

			</ElementGroup>

			<div className="area-toolbar">
		
				{/*
				// TODO: in develompent
				<Button icon='series-search' disabled={true}>
					Anteprima
				</Button>
				
				<Button 
					icon='th'
					disabled={true}
				>
					Esporta in CSV
				</Button>

				<Button 
					icon='function'
					disabled={true}
				>
					Applica la trasformazione
				</Button>
				*/}

				<ValueCheckbox
					label="Tempo relativo"
					checked={use_relative_time}
					onChange={(e) => {
						setUseRelativeTime(e.target.checked)
					}}
				/>


		
				<Button
					icon="refresh"
					disabled={!current_table || loading_series}
					onClick={() => {
						getSeriesList()
					}}
				>
					Aggiorna la lista
				</Button>
				<Popover 
					disabled={selected_rows.length === 0}
					position={PopoverPosition.BOTTOM}
					content={
						<div className="popover-delete-confirmation">
							
							Dal <strong>{Date(ts_first)}</strong><br/> 
							al <strong>{Date(ts_last)}</strong><br/><br/>
							Le seguenti misure saranno <b>eliminate</b>:<br/><br/>
							{selected_series.map((e) => {
								return (
									<strong>{e}<br/></strong>
								)
							})}
							<br/>
							Conferma?
							<br/>

							<center>
								<Button 
									disabled={loading_series}
									intent={Intent.DANGER}
									onClick={()=>{
										setLoadingSeries(true)
										api.deleteSeries(
											current_db, 
											current_table, 
											selected_series, 
											ts_first, ts_last
										).then(() => {
											getSeriesList()
										})
									}}
								>
									Si
								</Button>
								<Button onClick={(e) => e.stopPropagation(false)}>
									{/* TODO: find out how to close popover on click */}
									No
								</Button>
							</center>
						</div>
					}>
					<Button 
						icon='cross' 
						disabled={selected_rows.length === 0}>
						Elimina
					</Button>
				</Popover>

			</div>
			
			<ElementGroup caption="Panoramica" className="area-summary-table">
				<SummaryTable />
			</ElementGroup>

		</div>
		
	)
}

export default InfluxDBClient