
import React, { useEffect, useState } from 'react'

import { ProgressBar, Button } from "@blueprintjs/core"
import { getCantiereList, getDataloggersByCantiere } from "../api/wda_api.js"

import DataloggerTree from "../modules/DataloggerTree.js"
import CantiereSelector from "../modules/CantiereSelector.js"
import QuickSearchInput from "../components/QuickSearchInput.js"

import { connect } from 'react-redux'
import { store, Event } from '../redux.js'

import "../css/DataloggerView.scss"



const DataloggerView = (props) => {

	const {
		user,
		loading

	} = props

	const [ filter_string, setFilterString ] = useState("")

	const content = () => {
		return (
			<DataloggerTree 
				user= {user}
				filter_string = {filter_string}
			/>
		);
			
	}
	return (		
		<div className="view view-dataloggers">
			<div className="area area-cantiere-selector">
				<CantiereSelector 
					// cantieri={[...user.member_of, ...(user.role === "super" ? ["< tutti >"] : [])]} 
					current_cantiere={user.current_org}
				/>
				
				<QuickSearchInput 
					className="quick-search-datalogger"
					placeholder="Cerca..."
					onInput={setFilterString}
				/>
				
			</div>
			<div className="area area-datalogger-list">
				{loading ? <ProgressBar value={1} /> : content()}
			</div>
		</div>
	)
}


const mapStoreToProps = (store) => {
	return ({
		current_cantiere : store.current_cantiere,
		// loading : store.pending_ops.dataloggers // TODO: not working atm
		loading: false
	});
}

export default connect(mapStoreToProps)(DataloggerView)