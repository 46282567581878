import React, { useState, useEffect } from "react"
import { Tooltip, Button, Position } from '@blueprintjs/core'
import DateTimeInput from '../../components/DateTimeInput.js'
import { connect } from "react-redux"
import tempoFa from "../../components/tempo_fa.js"


const StartDate = (props) => { 
	const { cantiere_start_date_ts } = props
	const ext_controlled = props.date ? true : false
	const cantiere_start_date = new Date(cantiere_start_date_ts * 1000)
	const start_date = props.start_date ? props.start_date : cantiere_start_date
	const default_date = props.default_date || start_date
	const initial_date = props.date ? props.date : default_date
	const [same_date, setSameDate] = useState(false)
	const [new_date, setNewDate] = useState(initial_date)
	
	const onChange = (date) => {
		props.onChange(date)
		!ext_controlled && setNewDate(date)
	}

	useEffect(() => {
		setSameDate((ext_controlled ? props.date : new_date)?.getTime() === start_date?.getTime())
	}, [props])
	
	const current_set_date = ext_controlled ? props.date : new_date
	return (
		<>
			<Tooltip
				content={tempoFa(current_set_date)}
				position={Position.LEFT}
			>
				<DateTimeInput 
					date={current_set_date} 
					onChange={onChange}
				/>
			</Tooltip>
			
			&nbsp;
			<Tooltip 
				content={start_date.toLocaleString()}
				disabled={same_date}
				position={Position.LEFT}
			>
				<Button
					icon="double-chevron-left"
					disabled = {same_date}
					onClick={() => {
						onChange(start_date)
					}}
				>
					Inizio del cantiere
				</Button>
			</Tooltip>
			
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		cantiere_start_date_ts :  state.current_cantiere_data.start
	}
}


export default connect(mapStateToProps)(StartDate)