
import { Classes, Dialog } from '@blueprintjs/core'
import React from 'react'
import { connect } from "react-redux"
import '../css/MediaManager.scss'
import MediaManager from "../modules/MediaManager.js"
import { Event, store } from "../redux.js"

const MediaManagerModal = ({show, cantiere_name, cantiere_id}) => {
	return (
		<Dialog
			isOpen={show}
			onClose={() => {
				store.dispatch({type: Event.CLOSE_MEDIA_MANAGER})
			}}
			title="Gestore media"
			className="modal-media-manager"

		>
			<Classes.DIALOG_BODY>
				<MediaManager 
					key={`mm-${cantiere_name}-${show}`} 
					cantiere_name={cantiere_name} 
					cantiere_id={cantiere_id}
				/>
			</Classes.DIALOG_BODY>

		</Dialog>
	);

}

const mapStoreToProps = (store) => {
	return ({
		show : store.modal_media_manager,
		cantiere_name : store.current_cantiere_data?.name,
		cantiere_id : store.current_cantiere_data?.id
	})
}

export default connect(mapStoreToProps)(MediaManagerModal)