const differenceInDays = (date1, date2) => {
	const diffTime = date1 - date2;
	return Math.floor(diffTime / (1000 * 60 * 60 * 24));
};

const differenceInSeconds = (date1, date2) => {
	const diffTime = date1 - date2;
	return Math.floor(diffTime / 1000);
};

const tempoFa = (date) => {
	const now = new Date();
	const days_diff = differenceInDays(now, date);
	const seconds_diff = differenceInSeconds(now, date);
	const minutes_diff = Math.floor(seconds_diff / 60);
	const hours_diff = Math.floor(seconds_diff / 3600);
	
	
	var past_or_future = seconds_diff > 0 ? "fa" : "avanti"
	var time_string = `${Math.abs(hours_diff)} ore`
	if (Math.abs(days_diff) >= 1) {
		time_string = `${Math.abs(days_diff)} giorni`
	}
	else if (Math.abs(seconds_diff) < 60) {
		time_string = "< 1 minuto"
	}
	else if (Math.abs(minutes_diff) < 60) {
		time_string = `${Math.abs(minutes_diff)} minuti`
	}

	return `${time_string} ${past_or_future}`
	
};

export default tempoFa