import axios from 'axios'
import { translate } from '../i18n.js'
import { showNotification } from '../modules/Toast.js'
import { store } from '../redux.js'
// import fake_api from './testing.js'

const request_timeout = 10000;
const dev_mode = process.env.NODE_ENV === 'development';
const server_root = dev_mode ? "/wda-dashboard/v1" : "/wda-dashboard/v1";

const axios_params = { 
	baseURL: server_root,
	timeout: request_timeout, 
	withCredentials: true
};


const client = axios.create(axios_params);


/**
 * @brief using GET to retrieve data
 * @param {*} url 
 * @param {*} result
 */

const showNetworkTimeoutError = (locale) => {
	//const net_err_str = translate(locale, "errors", "network_error", true)
	//const no_conn_str = translate(locale, "errors", "no_connection", true)
	//showNotification ("danger", net_err_str, no_conn_str);
	showNotification("danger", "Errore della connessione", "Tempo di attesa scaduto. Verificare la connessione internet")
}

export const getData = (url, params = {}, timeout = request_timeout) => {
	const locale = store.getState()['lang'];
	return new Promise ((resolve, reject) => {
		client.get(url, {params : params, timeout: timeout}).then((response) => {
			resolve(response.data);
			//console.log("GET %s returned %d %o", url, response.status, response);
		}).catch ((err) => {
			if (err.response) {
				var code = err.response.status;
				var error_str = err.response.data.message;
				error_str = error_str ? error_str : "Errore del server"
				// !url.endsWith("info")
				if (code === 401) {
					// showNotification("danger", translate(locale, "errors", "session_expired", true));
					console.log("Server responded with 401: Session expired")
					
				} else if (code > 500) {
					showNotification("danger", "Server error", error_str)
				} 
				else {
					showNotification("danger", code, error_str)
				}
				console.log("GET %s : %d %s", url, code, error_str);
				reject (code, error_str);

			} else {
				showNetworkTimeoutError(locale)
				console.log("GET %s : %s", url, "Response timeout");
				reject (0, {message : "No connection"});
			}
		})
	});
}

export const getPlainText = (url, callback) =>
{
	client.get(url, {responseType : 'text/plain'}).then ((response) => {
		callback (response.text, true);
	})
	.catch ((error) => {
		callback (error, false);
		this.showNotification ("danger", "api error", error.message);
	});
}


/**
 * @brief using POST to send data
 * @param {*} url 
 * @param {*} data json object
 * @param {*} response function (response code, message) or 0 if unreachable
 */
export const postData = (url, data, timeout = 5000) => {
	const locale = store.getState['lang'];
	return new Promise((resolve, reject) => {
		client.post (url, data, {timeout: timeout ? timeout: request_timeout}).then((response) => {
			console.log("POST %s : %d %o", url, response.code, response);
			resolve(response.data)
		}).catch ((err) => {
			if (err.response) {
				var code = err.response.status;
				if (code === 304) {
					showNotification("warning", "Nessun valore e' stato modificato")
				}
				else {
					var message = err.response.data.message;
					showNotification ("danger", code, message);
				}
				console.log("POST %s : %d %s", url, code, message);
				reject(err.response);
			} else {
				showNetworkTimeoutError(locale)
				console.log("POST %s : %s", url, "Response timeout");
				reject({message : message});
			}
		})
	});
}

export const putData = (url, data, timeout = 5000) => {
	const locale = store.getState['lang'];
	return new Promise((resolve, reject) => {
		client.put (url, data, {timeout: timeout ? timeout: request_timeout}).then((response) => {
			console.log("PUT %s : %d %o", url, response.code, response);
			resolve(response.data)
		}).catch ((err) => {
			if (err.response) {
				var code = err.response.status;
				var message = err.response.data.message;
				showNotification ("danger", code, message);
				console.log("PUT %s : %d %s", url, code, message);
				reject(err.response);
			} else {
				showNetworkTimeoutError(locale)
				console.log("PUT %s : %s", url, "Response timeout");
				reject({message : message});
			}
		})
	});
}


export const deleteData = (url, data, timeout = 5000) => {
	const locale = store.getState['lang'];
	return new Promise((resolve, reject) => {
		client.delete(url, {data: data, timeout: timeout ? timeout: request_timeout}).then((response) => {
			console.log("DELETE %s %o : %o", url, data, response);
			resolve(response?.data || true)
		}).catch ((err) => {
			if (err.response) {
				var code = err.response.status;
				var message = err.response.data.message;
				showNotification ("danger", code, message);
				console.log("DELETE %s %o : %d %s", url, data, code, message);
				reject(err.response);
			} else {
				showNetworkTimeoutError(locale)
				console.log("DELETE %s : %s", url, "Response timeout");
				reject({message : message});
			}
		})
	});
}


export const upload = (url, form_data, progress_callback = null) => {
	return client.post(url, form_data, {
		headers: {
			'Content-Type' : 'multipart/form-data'
		},
		onUploadProgress: progress_callback
	})
}


const parseRequestError = (err) => {
	// output: { code: int, message: str }
	if (err.response) { 
		const code = err.response.status;
		const body = err.response.data;
		// try get "message" from JSON body
		var message = Array.isArray(body) ? body[0].message : body.message;
		if (!message) {
			if (body.errors && Object.keys(body.errors).length > 0) {
				// try collect errors from IIS server response
				message = Object.keys(body.errors).map((key) => {
					const messages = body.errors[key].join("\n    - ")
					return `${key}: \n${messages}\n`
				}).join("\n\n")
			}
			else {
				message = "Errore del server"
			}
			return {code: code, message: message}
		}
		return {...body, code: code, message: message}
	}
	// network error
	return {
		code: 0, 
		message : "Controllare la connessione"
	}
}


export const getData2 = (url, params = {}) => {
	return new Promise ((resolve, reject) => {
		client.get(url, {params : params}).then((response) => {
			resolve(response.data);
			//console.log("GET %s returned %d %o", url, response.status, response);
		}).catch((e) => {
			const err = parseRequestError(e)
			reject(err)
		})
	})
}


export const postData2 = (url, data, timeout = 5000) => {
	return new Promise((resolve, reject) => {
		client.post (url, data, {timeout: timeout ? timeout: request_timeout}).then((response) => {
			console.log("POST %s : %d %o", url, response.code, response);
			resolve(response.data)
		}).catch ((e) => {
			const err = parseRequestError(e)
			reject(err)
		})
	})
}

export const putData2 = (url, data, timeout = 5000) => {
	return new Promise((resolve, reject) => {
		client.put (url, data, {timeout: timeout ? timeout: request_timeout}).then((response) => {
			console.log("POST %s : %d %o", url, response.code, response);
			resolve(response.data)
		}).catch ((e) => {
			const err = parseRequestError(e)
			reject(err)
		})
	})
}


if (dev_mode) {
	console.log("*** Development mode ***");
	/* 
		getData = (url) => {
			console.log(url)
			return new Promise((resolve, reject) => {
				setTimeout(()=> {return resolve(fake_api[url])}, 200);
			})
		}
		postData = getData;
	*/
	
}
