
import React, { useState, useEffect } from 'react'
import { atom, useAtom, useAtomValue, getDefaultStore } from "jotai"
import { Icon, Button, ProgressBar, Popover, Intent, InputGroup, Tooltip} from "@blueprintjs/core"
import Select from 'react-select'
import ElementGroup from "../components/ElementGroup.js"
import ImageUploadArea from "../components/ImageUpload.js"
import { uploadMedia } from "../actions.js"
import { DropdownMenu } from "../components/Form.js"
import "../css/MediaManager.scss"
import * as api from '../api/media_api.js'
import { showNotification } from './Toast.js'

const store = getDefaultStore()

// shared state
const state = {
	file_list      : atom([]),
	loading        : atom(true)
}

const cantiereNameToDir = (cantiere: string) : string => {
	const re_excluded_chars = /[\\/:*?"<>|]/g
	// Replace all invalid characters with an underscore
	const sanitized = cantiere.replace(re_excluded_chars, '_')
	return sanitized
}

const getCantiereNameFromPath = (path) => {
	const parts = path.split('/')
	// Check if there are at least three parts and the third part is not a file
	if (parts.length >= 3 && !parts[2].includes('.')) {
		// Return the second-level directory (third element in the array)
		return parts[2]
	} 
	return ""
};

const updateGallery = (cantiere_name) => {
	store.set(state.file_list, [])
	store.set(state.loading, true)
	return api.getMedia().then((data) => {
		// filter file list
		const filtered_data = data.filter((item) => {
			const { name, path, size } = item
			const item_cantiere_name = getCantiereNameFromPath(path)
			// exclude items from different cantiere
			// but include items with no cantiere
			return (item_cantiere_name === cantiere_name || item_cantiere_name === "")
		})

		// sort filtered_data by path and then by name
		const sorted_by_name = filtered_data.sort((a, b) => a.name.localeCompare(b.name))
		const sorted_by_path = sorted_by_name.sort((a, b) => getCantiereNameFromPath(b.path).localeCompare(getCantiereNameFromPath(a.path)))
		store.set(state.file_list, sorted_by_path)
	}).finally(()=>{
		store.set(state.loading, false)
	});
}



const MediaThumbnail = ({path, name}) => {
	return (
		<div className='media-thumbnail'>
			<img src={path} alt={name} />
			<div className="thumbnail-footer">
				<div className="thumbnail-label">
					<nobr>{name}</nobr><br/>
					<nobr>Clicca per copiare il percorso</nobr>
				</div>

			</div>
		</div>
	)
}

const MediaManagerTable = ({items}) => {
	const file_list = useAtomValue(state.file_list)
	const MediaManagerTableRow = ({item}) => {
		const { name, path, size } = item
		const [wait_delete, setWaitDelete]  = useState(false)
		const cantiere_name = getCantiereNameFromPath(path)
		return (
			<tr>
				{/*Cantiere*/}
				<td className="td-cantiere-name">
					{cantiere_name}
				</td>
				{/*Nome*/}
				<td 
					className="td-name"
					onClick={() => {
						const url = `${window.location.origin}${path}`
						navigator.clipboard.writeText(url).then(() => {
							showNotification("success", "URL copiato", url)	
						}).catch((err) => {
							showNotification("danger", "Copia URL non riuscito", err)
						})	
					}}
				>
					<Tooltip 
						popoverClassName="bp3-popover-content-sizing"
						content={
							<MediaThumbnail
								name={name}
								path={path}
							/>
						}
					>
						<div>
							{name}
						</div>
					</Tooltip>
				</td>
				{/*Dimensioni*/}
				<td>
					{Math.ceil(size / 1024)} KB
				</td>
				{/*Elementi di controllo*/}
				<td>
					{!wait_delete &&
						<a onClick={() => {
							setWaitDelete(true)
							api.deleteMedia(path).then(() => {
								showNotification("warning", "Eliminazione", `Il file ${path} e' stato cancellato`)
								return updateGallery(cantiere_name)
							}).catch((e) => {
								showNotification("danger", "Eliminazione", `Error durante cancellazione ${path} : ${e.hasOwnProperty("data") ? e.data.message : JSON.stringify(e)}`)
							}).finally(() => {
								setWaitDelete(false)
							})
						}}
						>
							Elimina
						</a>
					}
				</td>
			</tr>
		)
	}

	return (
		<table className="media-manager-table" key={`mmtable-${file_list.length}`}>
			<tr>
				<th>Cantiere</th>
				<th>Nome</th>
				<th>Dimensioni</th>
				<th></th>
			</tr>
			{
				file_list.map((e) => {
					return (<MediaManagerTableRow item={e} />)
				})
			}
		</table>
	)
}


const MediaManager = ({cantiere_name, cantiere_id}) => {

	const file_list        = useAtomValue(state.file_list)
	const loading          = useAtomValue(state.loading)

	useEffect(() => {
		updateGallery(cantiere_name)
	}, [])

	const onUpload = (form_data, progress_callback) => {
		return uploadMedia(form_data, progress_callback).then(() => {
			updateGallery(cantiere_name);
		})
	}

	return (
		<div className="area-media-manager">
			<div className='area-upload'>
				<ImageUploadArea
					onSubmit={()=>{}}
					allowed_extensions=".png, .gif, .jpg, .jpeg, .svg"
					onUploadComplete={()=>{}}
					uploadHandler={onUpload}
					form_data={{
						"cantiere_id" : cantiere_id
					}}
				/>
			</div>
			<div className='area-search'>
				
			</div>
			<div className='area-file-table'>			
				{loading ?
					<ProgressBar value={1} /> : 
					<>{file_list.length === 0 ? 
						<>Nessun elemento </> :
						<MediaManagerTable />
					}</>
				}
			</div>
		</div>
	)
}

export default MediaManager