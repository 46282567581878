import React from 'react'
import { ProgressBar, Button} from '@blueprintjs/core'
import '../css/ImageUpload.css'
import { Icon } from '@blueprintjs/core'
import ElementGroup from '../components/ElementGroup.js'
import { uploadMap } from '../actions.js'

class ImageFileInfo extends React.Component {

	constructor(props) {
		super(props)

		const _URL = window.URL || window.webkitURL;
		const img = new Image();
		const object_url = _URL.createObjectURL(props.file)
		var width = 0;
		var height = 0;
		this.state = {
			width: " - ",
			height: " - ",
			image: null
		}
		img.onload = () => {
			this.setState ({
				width: img.naturalWidth,
				height: img.naturalHeight,
				image : img,
			})

			props.imageInfoCallback (img.naturalWidth, img.naturalHeight)
			//_URL.revokeObjectURL(object_url);
		}
		img.src = object_url;
	}
	render() {
		return (
			<div className='area area-image-preview'>
				<div className='name-label'>File</div><div className='name-value'>{this.props.file.name}</div>
				<div className='size-label'>Dimensione</div><div className='size-value'>{(this.props.file.size / 1024.0 / 1024.0).toFixed(2)} MB</div>
				<div className='res-label'>Risoluzione</div><div className='res-value'>{`${this.state.width} x ${this.state.height}`}</div>
				{this.state.image && <img src={this.state.image.src} height={100}/>}
			</div>
		)
	}
}


class ImageUploadArea extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			selected_file : null,
			remote_file : null,
			upload_progress : null,
			width: 0,
			height: 0,
		}

		this.onFileChange = (e) => {
			e.preventDefault();
			const selected_file = e.target.files[0];
			this.setState({
				selected_file : selected_file
			});

			// begin an upload as soon as file is selected
			this.beginUpload(selected_file);
		};

		this.uploadHandler = props.uploadHandler || uploadMap;

		this.onDragBegin = (e) => {
			e.target.classList.add("drag-over");
		}

		this.onDragEnd = (e) => {
			e.target.classList.remove("drag-over");
		}

		this.onDrop = (e) => {

		}

		this.onProgress = (progress) => {
			this.setState({
				upload_progress : progress
			})
		}

		this.beginUpload = (selected_file) => {
			var form_data = new FormData();
			form_data.append("file", selected_file);
			// append additional form data passed in props
			if (props.hasOwnProperty("form_data")) {
				Object.keys(props.form_data).forEach((key) => {
					form_data.append(key, props.form_data[key])
				})
			}

			this.uploadHandler (form_data, this.onProgress).then((resp) => {
				if (resp == null || !resp.hasOwnProperty('data') || !resp.data.hasOwnProperty("file_name")) {
					console.error("Upload error: %o", resp)
					return
				}
				this.setState({
					remote_file: resp.data.file_name
				});
				console.log("Uploaded as " + resp.data.file_name)
				this.props.onUploadComplete({
					file_name: resp.data.file_name,
					original_file: selected_file.name,
					width: this.state.width,
					height: this.state.height
				})

			}).finally(() => {
				this.setState({upload_progress : null});
			})
		} 
		
	}

	render() {
		return (
			<ElementGroup caption="Caricamento immagine">
				{this.state.selected_file == null && 
					<input 
						id='file-upload-form'
						type='file' 
						className='input-file' 
						name='file'
						onChange={this.onFileChange}
						accept={this.props.allowed_extensions}
						enctype='multipart/form-data'
					/>
				}
					
				{this.state.upload_progress != null && 
					<>
						<ProgressBar
							intent="primary"
							value={this.state.upload_progress.loaded / this.state.upload_progress.total}
						/>
						<span>{(this.state.upload_progress.loaded / 1024.0).toFixed(1)}</span> / <span>{(this.state.upload_progress.total / 1024.0).toFixed(1)}</span> KB 
						(<span>{((this.state.upload_progress.loaded / this.state.upload_progress.total)*100.0).toFixed(0)} %</span>)
					</>
				}
				{this.state.selected_file != null && 
					<ImageFileInfo 
						file={this.state.selected_file} 
						imageInfoCallback={(width, height) => {
							this.setState({
								width: width,
								height: height
							})
						}}	
					/>
				}
			</ElementGroup>	
		);
	}
}

export default ImageUploadArea