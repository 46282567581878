import React, { useState, useEffect } from "react"
import { Button, Classes, Dialog, Intent, ButtonGroup, InputGroup, Checkbox, NumericInput } from '@blueprintjs/core'
import { Menu, MenuItem, ContextMenu } from '@blueprintjs/core'
import { Table, Column, Cell } from "@blueprintjs/table"
import ElementGroup from "../components/ElementGroup.js"
import StartDate from "../forms/components/StartDate.js"
import { FormValue, ValueCheckbox } from "../components/Form.js"
import { openDeleteModal, modifyVirtualChannelGroup, updateTree } from "../actions.js"
import { IGetVChannelResponse, IGetVChannelStatResponse, IGetVChannelStatVar } from "../api/interfaces.js"
import { TooltipContent, Tooltip as TooltipWrapper } from "../modules/Tooltip.js"
import { showNotification } from "../modules/Toast.js"
import { connect } from 'react-redux'
import { Event, store } from '../redux.js'
import { atom, useAtom, getDefaultStore } from "jotai"
import * as api from '../api/wda_api.js'


const timestampToDateString = (timestamp: int): str => {
	var date = new Date(timestamp);
	return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}


const getLowestTimeFromVars = (vars: IGetVChannelStatVar[]): int => {
	return vars.map((_var) => (_var.time)).reduce((prev_var, current_var) => {
		if (prev_var === 0 || current_var < prev_var) {
			return current_var
		}
		return prev_var
	}, 0)
}


const selection_state = atom({});

interface ITableEntry {
	id: numeric;
	name: string;
	verified: boolean;
	raw_value: numeric;
	zero_date: numeric;
	success: boolean;
	value?: numeric;
	vars?: IGetVChannelStatVar[];
}


const vc_stats_mixin = {
	"name": "",
	"verified": false,
	"raw_value": 0,
	"zero_date": 0,
	"value": 0
	//"new_zero_value" : 0,
}

const getStatsInitial = (vchannel: IGetVChannelResponse) => {
	return {
		...vc_stats_mixin,
		id: vchannel.id,
		name: vchannel.name,
		zero_value: vchannel.zero_value
	}
}


const getNewStatsVerify = (vchannel: IGetVChannelResponse, date_timestamp: int, timeout_override = -1): Promise<ITableEntry> => {
	const timeout = (timeout_override === -1) ? vchannel.channel_timeout : timeout_override
	return api.getVirtualChannelTimePoint(vchannel.db_name_raw, date_timestamp, timeout, vchannel.expression).then((tp_stat) => {
		var stat: ITableEntry = { ...vc_stats_mixin, ...tp_stat }
		stat.verified = true
		if (stat.success) {
			stat.id = vchannel.id
			stat.name = vchannel.name
			stat.zero_date = getLowestTimeFromVars(stat.vars)
			stat.raw_value = stat.value
		}
		return stat
	})
}


interface VirtualChannelsSummaryTableProps {
	data: ITableEntry[];
	expected_element_count: number;
}

const VirtualChannelsSummaryTable = (props: VirtualChannelsSummaryTableProps) => {
	const {
		data = [], // data: ITableEntry[];
		expected_element_count = 0,  // specify this to render table skeleton while loading actual data
	} = props

	const [selection, setSelection] = useAtom(selection_state)

	const selectRow = (item_id, checked) => {
		setSelection((previous_state) => {
			return ({
				...previous_state,
				[item_id]: checked
			})
		})
	}


	const CheckboxContextMenu = (props) => {
		const [selection, setSelection] = useAtom(selection_state)
		const selectAllOrInvert = (select_all: boolean, invert : boolean = false) => {
			const result = {}
			Object.keys(selection).forEach(key => {
				result[key] = invert ? !selection[key] : select_all;
			});
			setSelection(result)
		}

		return (	
			<Menu>
				<MenuItem 
					text="Seleziona tutto" 
					onClick={() => {
						selectAllOrInvert(true)
					}} 
				/>
				<MenuItem 
					text="Rimuovi la selezione" 
					onClick={() => {
						selectAllOrInvert(false)
					}}
				/>
				<MenuItem
					text="Inverti la selezione"
					onClick={() => {
						selectAllOrInvert(false, true)
					}}
				/>
				
			</Menu>
		)
	}

	const checkboxContextMenuHandler = (e) => {
		e.preventDefault();
		ContextMenu.show(<CheckboxContextMenu/>, {left: e.clientX, top: e.clientY }, () => {})
	}

	const renderCell = (row_index, col_index) => {
		const item = { ...data[row_index], key: `cell-${data[row_index].id}` };
		var state = "Non modificato"
		var cell_class = ""
		if (item.verified) {
			if (item.success) {
				state = "OK"
				cell_class = "cell-ok"
			}
			else {
				state = item.message
				cell_class = "cell-error"
			}
		}
		const verify_success = item.verified && item.success
		switch (col_index) {
			case 0:
				// Checkbox
				return (
					<Cell className>	
						<Checkbox
							checked={selection?.[item.id]}
							onChange={(e) => selectRow(item.id, e.target.checked)}
						/>
					</Cell>
				);
			case 1:
				// Nome
				return <Cell className={cell_class}>{item.name}</Cell>;
			case 2:
				// Data di misura
				return <Cell className={cell_class}>{verify_success && timestampToDateString(item.zero_date)}</Cell>;
			case 3:
				// Compensazione
				return <Cell className={cell_class}>{verify_success ? item.raw_value : item.zero_value}</Cell>;
			case 4:
				// Comp. originale
				return <Cell className={cell_class}>{item.zero_value}</Cell>
			case 5:
				// Delta
				return <Cell className={cell_class}>{verify_success && <>{(item.raw_value - item.zero_value).toFixed(4)}</>}</Cell>
			case 6:
				// Stato
				return <Cell className={cell_class}>{state}</Cell>
			default:
				return <Cell />;
		}
	}


	return (
		<span
			onContextMenu = {checkboxContextMenuHandler}
		>
			<Table
				onContextMenu = {checkboxContextMenuHandler}
				numRows={props.data.length}
				// columnWidths={[30, 150,100,100,100,100,120,120]}
				columnWidths={[50, null, null, null, null, null, null]}
				className="virtual-channel-group-summary-table"
			>	
				<Column name="Sel"                       cellRenderer={renderCell} />
				<Column name="Nome"                      cellRenderer={renderCell} />
				<Column name="Data di misura"            cellRenderer={renderCell} />
				<Column name="Azzeramento"               cellRenderer={renderCell} />
				<Column name="Azzeramento precedente"    cellRenderer={renderCell} />
				<Column name="Delta"                     cellRenderer={renderCell} />
				<Column name="Stato"                     cellRenderer={renderCell} />
			</Table>
		</span>
	)

	/*
	return (
		<>Loading {expected_element_count} elements ...</>
	)
	*/
}



const FormVirtualChannelGroupOperations = (props) => {
	const { show, args, all_vchannels, cantiere_id } = props
	const group_name = args
	const vchannels = all_vchannels.filter((vc) => (vc.group === group_name))

	const [busy, setBusy]                           = useState(false)
	const [summary_data, setSummaryData]            = useState({}) // <{ [key: number] : ITableEntry[] }>
	const [option_enable, setOptionEnable]          = useState(null)
	const [option_new_date, setOptionNewDate]       = useState(null)
	const [option_new_name, setOptionNewName]       = useState(null)
	const [option_zero_date, setOptionZeroDate]     = useState(null)
	const [option_zero_date_verified, setOptionZeroDateVerified] = useState(false)
	const [selection, setSelection]                   = useAtom(selection_state); // <{ [key: number]: boolean }>
	const [timeout_value, setTimeoutValue]            = useState(-1)
	const [timeout_override, setTimeoutOverride]      = useState(false)
	const [start_date_override, setStartDateOverride] = useState(false)
	const [selection_count, setSelectionCount]        = useState(0)

	const optionsChanged = () => {
		return !(
			option_enable === null &&
			option_new_name === null &&
			option_zero_date === null
		)
	}

	const resetState = () => {
		setOptionEnable(null)
		setOptionNewDate(null)
		setOptionNewName(null)
		setOptionZeroDate(null)
		setOptionZeroDateVerified(false)
		setTimeoutOverride(false)
		setTimeoutValue(-1)
		setStartDateOverride(false)
		setSelection([])
	}

	const initData = () => {
		setSummaryData([])
		vchannels.forEach((vchannel: IGetVChannelResponse) => {
			setSummaryData((previous_data) => ({
				...previous_data,
				[vchannel.id]: getStatsInitial(vchannel)
			}));
		})
		// unselect all
		setSelection(Object.fromEntries(vchannels.map((vchannel) => {
			return ([vchannel.id, false])
		})))
	}


	const reloadData = () => {
		// setBusy(true)
		resetState()
		initData()
	}


	const verifyData = () => {
		var promises: Promise<IGetVChannelStatResponse[]> = []
		setBusy(true)
		vchannels.forEach((vchannel: IGetVChannelResponse) => {
			// resolve virtual channel stats one by one
			// const promise = mockapi_get_new_stats_verify(vchannel, option_zero_date).then((stat : IGetVChannelStatResponse) => {
			const promise = getNewStatsVerify(vchannel, option_zero_date, timeout_override ? timeout_value : -1).then((stat: IGetVChannelStatResponse) => {
				console.debug("Resolved vchannel %o with summary %o", vchannel, stat)
				setSummaryData((previous_state) => ({
					...previous_state,
					[vchannel.id]: {
						...stat,
						name: vchannel.name,
						zero_value: vchannel.zero_value
					}
				}));
				setSelection((previous_state) => ({
					...previous_state,
					[vchannel.id]: stat.success
				}));
			})
			promises.push(promise)
		})
		Promise.all(promises).then(() => {
			// run this when all requests are complete
			setBusy(false)
			setOptionZeroDateVerified(true)
		}).finally(() => {
			setBusy(false)
		})
	}


	const applyTimeoutForSelectedChannels = (): Promise => {
		const requests: Promise[] = []
		for (const id in summary_data) {
			const item = summary_data[id]
			if (selection?.[id]) {
				const request = api.modifyVirtualChannel({
					id: parseInt(id),
					channel_timeout: timeout_value
				})
				requests.push(request)
			}
		}
		return Promise.all(requests)
	}


	const applyStartDateForSelectedChannels = (): Promise => {
		const requests: Promise[] = []
		for (const id in summary_data) {
			const item = summary_data[id]
			if (selection?.[id]) {
				const request = api.modifyVirtualChannel({
					id: parseInt(id),
					last_import: option_new_date
				})
				requests.push(request)
			}
		}
		return Promise.all(requests)
	}


	const applyZeroValueForSelectedChannels = (): Promise => {
		const requests: Promise[] = []
		for (const id in summary_data) {
			const item = summary_data[id]
			if (selection?.[id] && item?.verified && item?.success) {
				const request = api.modifyVirtualChannel({
					id: parseInt(id),
					zero_value: item.raw_value
				})
				requests.push(request)
			}
		}
		return Promise.all(requests)
	}


	const applyData = (data) => {
		setBusy(true)
		var modified_parameters = {}
		// check for modified parameters
		if (option_enable !== null) {
			modified_parameters.enabled = option_enable
		}
		if (option_new_name !== null) {
			modified_parameters.new_name = option_new_name
		}
		if (option_zero_date !== null) {
			modified_parameters.zero_date = option_zero_date
		}

		modifyVirtualChannelGroup({
			cantiere_id: cantiere_id,
			name: group_name,
			...modified_parameters
		}).then(() => {
			if (option_zero_date !== null && option_zero_date_verified) {
				return applyZeroValueForSelectedChannels().then(hide).catch((e) => {
					console.error("Error setting zero value for multiple vchannels: %o", e)
				})
			}
			hide()
			return Promise.resolve()

		}).finally(() => {
			setBusy(false)
		})

	}

	useEffect(() => {
		// on modal appear
		if (show) {
			reloadData()
			initData()
		}

	}, [show, args])

	useEffect(() => {
		setOptionZeroDateVerified(false)
	}, [option_zero_date])

	useEffect(() => {
		if (timeout_override) {
			setTimeoutValue(30)
		}
		else {
			setTimeoutValue(-1)
		}
	}, [timeout_override])

	useEffect(() => {
		setSelectionCount(Object.values(selection).filter(Boolean).length)
	}, [selection])

	const hide = () => {
		resetState()
		store.dispatch({
			type: Event.CLOSE_VCHANNEL_GROUP_OPS_MODAL
		})
	}
	return (
		<>
			{
				show && <Dialog
					portalClassName="modal-on-top"
					className={busy ? 'busy' : ''}
					isOpen={true}
					onClose={hide}
					title={`Gruppo canali virtuali "${group_name}"`}

				>
					<Classes.DIALOG_BODY>
						<ElementGroup caption="Azzeramento totale">
							<VirtualChannelsSummaryTable
								expected_element_count={vchannels.length}
								data={Object.values(summary_data)}
							/>
						</ElementGroup>


						<ElementGroup caption="Operazioni di massa" className="group-virtual-channel-ops">
							<FormValue
								label="Nome"
								modified={option_new_name !== null && group_name !== option_new_name}
							>
								<InputGroup
									value={option_new_name || group_name}
									onChange={(event) => {
										setOptionNewName(event.target.value)
									}}
								/>
							</FormValue>

							

							<FormValue
								label="Data di azzeramento"
								modified={option_zero_date !== null}
							>
								<StartDate
									{...(option_zero_date !== null ? { date: new Date(option_zero_date * 1000) } : {})}
									onChange={(new_date) => {
										const _new_date = new_date ? Math.floor(new_date.getTime() / 1000) : option_zero_date
										setOptionZeroDate(_new_date)
									}}
								/>&nbsp;

							</FormValue>

							<FormValue
								label={
									<ValueCheckbox
										checked={timeout_override}
										onChange={(e) => setTimeoutOverride(e.target.checked)}
										label="Timeout personalizzato"
									/>
								}
								className="form-value-apply"
							>
								<TooltipWrapper content={TooltipContent.VIRTUAL_CHANNEL_CHANNEL_TIMEOUT}>
									<NumericInput
										disabled={!timeout_override}
										className="input-numeric"
										selectAllOnFocus={true}
										value={timeout_value === -1 ? "per canale" : timeout_value}
										stepSize={10}
										onValueChange={(v) => {
											setTimeoutValue(v)
										}}
									/>
								</TooltipWrapper>


								<Button
									className="btn-verify"
									disabled={busy || option_zero_date === null}
									icon="tick"
									onClick={verifyData}
									text="Verifica"
								/>
							</FormValue>

							{
								timeout_override &&
								<FormValue
									label="Impostare il timeout per i canali selezionati"
								>
									<Button
										intent="success"
										className="btn-verify"
										disabled={busy || timeout_value <= 0 || (selection_count === 0)}
										onClick={() => {
											setBusy(true)
											applyTimeoutForSelectedChannels().then(() => {
												showNotification("success", "Timeout applicato", `${selection_count} canali selezionati sono stati modificati`)
												updateTree()
											}).catch((e) => {
												showNotification("danger", "Errore", `Errore durante la modifica del timeout: ${e.code} ${e.message}`)
											}).finally(() => {
												setBusy(false)
											})
										}}
										text={`Applica timeout (${selection_count})`}
									/>
								</FormValue>
							}
							<br />

							<FormValue
								label={
									<ValueCheckbox
										checked={start_date_override}
										onChange={(e) => setStartDateOverride(e.target.checked)}
										label="Cambiare Data di inizio importazione"
									/>
								}
								className="form-value-apply"
							>
								{ start_date_override &&
									<StartDate
										{...(option_new_date !== null ? { date: new Date(option_new_date * 1000) } : {})}
										onChange={(new_date) => {
											setOptionNewDate(new_date ? Math.floor(new_date.getTime() / 1000) : option_new_date)
										}}
									/>
								}
							</FormValue> 
							{ start_date_override && 
								<FormValue
									label="Impostare la data d'inizio per i canali selezionati"
								>
									<Button
										intent="success"
										className="btn-verify"
										disabled={busy || (selection_count === 0)}
										onClick={() => {
											setBusy(true)
											// TODO: fix rename add
											applyStartDateForSelectedChannels().then(() => {
												showNotification("success", "Data d'inizio di importazione applicata", `${selection_count} canali selezionati sono stati modificati`)
												updateTree()
											}).catch((e) => {
												showNotification("danger", "Errore", `Errore durante la modifica del timeout: ${e.code} ${e.message}`)
											}).finally(() => {
												setBusy(false)
											})
										}}
										text={`Applica la data d'inizio (${selection_count})`}
									/>
								</FormValue>
							}
							
								
							<br />

							<div className="button-bar">
								<div align="left">
									<ButtonGroup>
										<Button
											icon="play"
											disabled={option_enable === true}
											onClick={() => {
												setOptionEnable(true)
											}}
										>
											Abilita
										</Button>
										<Button
											icon="pause"
											disabled={option_enable === false}
											onClick={() => {
												setOptionEnable(false)
											}}
										>
											Disabilita
										</Button>
									</ButtonGroup>
								</div>
								<div align="right">
									<Button
										icon="trash"
										onClick={() => {
											openDeleteModal("vchannel-group", group_name)
										}}
									>
										Elimina
									</Button>
								</div>
							</div>
						</ElementGroup>
						{optionsChanged() &&
							<ElementGroup
								caption={
									<>
										Azioni per <b>{vchannels.length} canali virtuali</b> del gruppo <b>{group_name}</b>
									</>
								}
							>
								{option_enable !== null &&
									<>
										{option_enable ?
											<>- Abilitare tutti i canali. </> :
											<>- Disabilitare tutti i canali. </>
										}
										<a onClick={() => setOptionEnable(null)}>Annulla</a><br />
									</>
								}
								{option_new_name !== null &&
									<>
										- Riassegnare i canali del gruppo <b>{group_name}</b> al gruppo <b>{option_new_name}</b>.
										<a onClick={() => setOptionNewName(null)}> Annulla</a><br />
									</>
								}
								{option_zero_date !== null &&
									<>
										- {!option_zero_date_verified && <b>(Non confermato) </b>}Aggiornare i valori di azzeramento per la data <b>{new Date(option_zero_date * 1000).toLocaleString()}</b>.
										<a onClick={() => setOptionZeroDate(null)}> Annulla </a><br />
									</>
								}
							</ElementGroup>
						}
					</Classes.DIALOG_BODY>
					<Classes.DIALOG_FOOTER>
						<Button
							intent={Intent.SUCCESS}
							disabled={busy || !optionsChanged()}
							onClick={applyData}
						>
							Applica
						</Button>
						<Button
							icon="undo"
							disabled={busy || !optionsChanged()}
							onClick={reloadData}
						>
							Ripristina
						</Button>
						<Button
							onClick={hide}
						>
							Chiudi
						</Button>
					</Classes.DIALOG_FOOTER>

				</Dialog>
			}
		</>
	)
}


const mapStateToProps = (state) => {
	const m = state.modal_vchannel_group_ops
	return ({
		show: m.show,
		args: m.args,
		all_vchannels: state.vchannels,
		cantiere_id: state.current_cantiere_id
	})
}

export default connect(mapStateToProps)(FormVirtualChannelGroupOperations)

