import { getData, postData, putData, deleteData} from './requests.js'


export const getDatabases = () => {
	return getData('/api/influx/databases')
}

export const getDatabasesByCantiereId = (id) => {
	return getData('/api/influx/databases', {cantiere_id: id})
}

export const getTables = (database) => {
	return getData('/api/influx/tables', {database: database})
} 

export const getSeriesList = (database, table, ts_first, ts_last) => {
	return getData('/api/influx/series', {database: database, table: table, ts_first : Math.floor(ts_first/1000), ts_last: Math.floor(ts_last/1000)});
}

export const getSeriesData = (database, table, param) => {
	return getData('/api/influx/data', {database: database, table: table, ...param}); // ... series={...}, 
}

export const createDatabase = (data) => {
	return putData('/api/influx/database', data);
}

export const dropDatabase = (data) => {
	return deleteData('/api/influx/database', data);
}

export const deleteSeries = (database, table, measurements, ts_first = 0, ts_last = new Date().getTime()) => {
	return deleteData('/api/influx/data', {
			database: database, 
			table: table,
			measurements: measurements,
			ts_first: Math.floor(ts_first/1000),
			ts_last:  Math.floor(ts_last/1000)
		}, 30000
	);
}

export const dropTable = (database, table) => {
	return deleteData('/api/influx/table', {
		database: database,
		table: table
	})
}

export const doQuery = (query_string) => {
	return postData('/api/influx/query', {query : query_string});
}
